




import { Component, Vue } from 'vue-property-decorator'
import RouterViewWithError from '@/general/components/layouts/RouterViewWithError.vue'

@Component({
  components: {
    RouterViewWithError
  }
})
export default class NotFound extends Vue {
  created() {
    this.$store.commit('notFound', true)
  }
}
